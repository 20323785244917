import * as React from "react";

import Seo from "../components/seo";
import Layout from "../components/layout";
import useFillHeight from "../components/hooks/use-fill-height";
import TopHeroVideo from "../components/parts/top-hero-video";
import TopNews from "../components/parts/top-news";
import TopRecentWorks from "../components/parts/top-recent-works";
import TopCapabilities from "../components/parts/top-capabilities";
import TopTanikawaSection from "../components/parts/top-tanikawa-section";

import "../styles/global/base.scss";
import "../styles/index.scss";

const IndexPage = () => {

  useFillHeight();

  return (
    <React.Fragment>
      <Seo pageClass="indexPage" />
      <TopHeroVideo />
      <Layout>
        <TopNews />
        <TopRecentWorks />
        <TopCapabilities />
        <TopTanikawaSection />
      </Layout>
    </React.Fragment>
  );
};

export default IndexPage;
