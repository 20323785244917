import * as React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import { Container, Row, Col } from 'react-bootstrap';
import NewsHeadline from "./news-headline";

const MAX_DISPLAY_NEWS = 6;

const TopNews = () => {
  const { allNews } = useStaticQuery(
    graphql`
      query {
        allNews: allMarkdownRemark(sort: {fields: frontmatter___date, order: DESC}) {
          edges {
            node {
              id
              frontmatter {
                date
                title
                topNews
              }
              fields {
                slug
              }
            }
          }
        }
      }
    `
  );
  const topNews = allNews.edges.filter((edge) =>
    edge.node.frontmatter.topNews === true
  );
  if (topNews.length === 0) {
    return null;
  }
  const displayNews = topNews.slice(0, MAX_DISPLAY_NEWS);

  return (
    <Container className="newsOuter">
      <Row>
        <Col md={{ span: 10, offset: 1 }}>
          <h4>News</h4>
          <dl>
            {
              displayNews.map(({ node }) => (
                <NewsHeadline node={node} key={node.id} />
              ))
            }
          </dl>
          <p className="text-end small"><Link to="/news/">News Index →</Link></p>
        </Col>
      </Row>
    </Container>
  );
};

export default TopNews;
