import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image";
import { Container, Row, Col } from 'react-bootstrap';
import TopCapabilityContent from "./top-capability-content";

const interval = 4000;

const TopCapabilities = () => {

  const { imageDatas } = useStaticQuery(
    graphql`
      query {
        imageDatas: allTopCapabilitiesJson {
          edges {
            node {
              image {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, width: 960)
                }
              }
              id
              objectPosition
            }
          }
        }
      }
    `
  );
  const datas = imageDatas.edges;
  const [index, setIndex] = React.useState(0);

  React.useEffect(() => {
    const changeSlide = () => {
      const targetIndex = (datas.length - 1 <= index) ? 0 : index + 1;
      setIndex(targetIndex);
    };
    const id = setTimeout(changeSlide, interval);
    return (() => {
      clearTimeout(id);
    });
  }, [index, datas.length]);

  return (
    <React.Fragment>
      <Container fluid className="justify-content-center capabilitiesHeader">
        <StaticImage src="../../images/top/capabilities_1.jpg" layout="constrained" alt="" objectPosition="50% 50%" />
        <div className="capabilitiesBg">
          <h2>CAPABILITIES</h2><h2>CAPABILITIES</h2><h2>CAPABILITIES</h2><h2>CAPABILITIES</h2><h2>CAPABILITIES</h2>
        </div>
      </Container>
      <Container className="capabilitiesLead">
        <div className="leadImg">
          <div className="leadSlideshow">
            <GatsbyImage image={getImage(datas[index].node.image)} layout="constrained" alt="" objectPosition={datas[index].node.objectPosition} />
          </div>
        </div>
        <Row>
          <Col>
            <div>
              <h2>Capabilities</h2>
              <p>
                Creative teams that<br />comprehensively design<br />spaces and experiences<br />based on imaging
              </p>
              <p>
                映像を基点に、<br className="d-lg-none" />空間、体験を総合的にデザインする<br />クリエイティブチーム
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      <Container className="capabilitiesBody">
        <Row>
          <TopCapabilityContent number="01" head="Production Work">
            <p>
              さまざまなメディアにおける映像企画、広告、イベント映像などに加え、コンセプト映像、インスタレーションなど、映像に関わる全てのデザインワークに対応します。ドキュメンタリー映画の企画・制作も行っています。
            </p>
          </TopCapabilityContent>
          <TopCapabilityContent number="02" head="Creative Planning">
            <p>
              制作・編集・撮影など社内のプロフェッショナルスタッフとさまざまな国内外の協力会社とのネットワークで、状況に応じた最適なチームを編成し、お客様のご要望にお応えします。
            </p>
          </TopCapabilityContent>
          <TopCapabilityContent number="03" head="Experiential Design">
            <p>
              映像を取り巻く環境をトータルに演出します。視るだけでなく体感する空間デザインと体験設計を生み出し映像表現の可能性を追求していきます。
            </p>
          </TopCapabilityContent>
          <Col>
            <StaticImage src="../../images/top/capabilities_3.jpg" alt="" layout="constrained" />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default TopCapabilities;
