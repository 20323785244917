import * as React from "react";

import Tanikawa1 from "../../svgs/tanikawa_1.svg";
import Tanikawa2 from "../../svgs/tanikawa_2.svg";
import Tanikawa3 from "../../svgs/tanikawa_3.svg";
import Tanikawa4 from "../../svgs/tanikawa_4.svg";
import TanikawaText1 from "../../svgs/tanikawa_text_1.svg";
import TanikawaText2 from "../../svgs/tanikawa_text_2.svg";

const TopTanikawaSection = () => {
  return (
    <div className="tanikawa-outer">
      <p className="poem-title"><Tanikawa1 /></p>
      <div className="rolling-bg-outer">
        <div className="rolling-bg-inner">
          <p className="rolling-bg"><Tanikawa3 /></p>
          <p className="rolling-bg"><Tanikawa4 /></p>
        </div>
      </div>
      <div className="poem-body">
        <p><TanikawaText1 /></p>
        <p><TanikawaText2 /></p>
      </div>
      <p className="poem-author">
        <Tanikawa2 />
      </p>
    </div>
  );
};

export default TopTanikawaSection;
